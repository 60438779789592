/* src/App.css */

:root {
  --color-bg: #040c18;
  --color-bg-light: linear-gradient(to bottom, #040c18, #051021, #04142b, #011735, #00193f);
  --gradient-text: /* define your gradient here */;
}

.App {
}

.gradient__bg {
  background-image: var(--color-bg-light);
}

.underline-text {
  text-decoration: underline;
}

.gradient__text {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-center {
  0% { transform: scale(0.5); }
  100% { transform: scale(1); }
}

.app-content {
  display: flex;
  height: 100%;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.UserInput {
  margin-top: auto;
}

.header-userinput-container {
  overflow-y: auto;
}

@media screen and (max-width: 700px) {
  .section__padding { padding: 4rem; }
  .section__margin { margin: 4rem; }
}

@media screen and (max-width: 550px) {
  .section__padding { padding: 4rem 2rem; }
  .section__margin { margin: 4rem 2rem; }
}