.helix-chatPage{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(135deg, #0077be, #00a86b);
    padding-top: 10vh;
    align-items: center;
    min-height: 100vh;
    }


.helix-chatPage-split{
display: flex;
    flex-direction: column;
    width: 100%;
    background-image:  linear-gradient(to top, #ffffff, #fefcfe, #fffafb, #fff7f6, #fff5f0, #fff4ee, #fff2eb, #fff1e9, #ffefec, #ffedf2, #feedf9, #f6eeff);
}
.helix-chatPage-sherpa{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 5%;
background-image: linear-gradient(to bottom, #ffd5e0, #f6d7e5, #eedae8, #e7dce7, #e2dee5, #e3e1e6, #e5e4e7, #e7e7e7, #ededed, #f3f3f3, #f9f9f9, #ffffff);}

.helix-chatPage-custom-tool{
    display: flex;
    flex-direction: column;
    width: 100%;

    background-image: linear-gradient(to top, #ffffff, #fcfbff, #f9f6ff, #f6f2ff, #f4edff, #f6e9fd, #fae6f9, #fde2f5, #ffe0eb, #ffdee1, #ffded7, #ffdfce);
}

.chatPage-topMessage, .chatPage-topMessage p{
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 0rem;
    text-align: center;  /* Ensure the text inside the paragraph is also centered */
}
.chatPage-topMessage-sherpa,.chatPage-topMessage-sherpa h3{
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 27px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 0rem;
    text-align: center;  /* Ensure the text inside the paragraph is also centered */
}
.chatArea-inside-container{
    display: flex;
    flex-direction: column;
    width: 60%;
}
/*.chat-history-container {
    flex: .15;
    flex-direction: column;
    padding: 20px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    height: calc(100vh - 40px);
}
*/
.close-modal-btn{
    font-family: var(--font-family);
    font-weight: 800;
    width:3%;
    height: 3vh;
}
.userInput-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: white;

}
.light-mode {
  background-color: white;
  color: black;
  /* other light mode styles */
}

.dark-mode {
  background-color: black;
  color: white;
  /* other dark mode styles */
}

/* Add more styles for the switch to match your design needs */
/* Style for the dropdown menu */
.chat-type-dropdown select {
  font-family: var(--font-family);
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  margin-left: 10px; /* Adjust as needed */
}

/* Styles to make the dropdown blend into the chat interface */
.chat-type-dropdown {
  /* You may want to adjust this to position the dropdown as per your design */
}
.parent-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.left-content {
  flex: 1;  /* Adjust the flex value as needed */
  /* Other styling */
  margin-left: 5%;

}

.right-content{
 flex: 1;
}
.chat-PDF-container{
height: 100%;
}

@media (max-width: 1000px){
.chatArea-inside-container {
    width: 70%;
  }

}
@media (max-width: 780px){
.chatArea-inside-container {
    width: 80%;
  }
.helix-chatPage{
padding-top: 18vh;
}
}

@media (max-width: 550px){
.chatArea-inside-container {
    width: 90%;
  }

}
@media (max-width: 480px){
.chatArea-inside-container {
    width: 95%;
  }

}
@media (max-width: 420px){
.close-modal-btn{
    width: 5%;
}
}