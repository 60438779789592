.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  font-family: var(--font-family);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 90px;
  position: relative;
}

.navbar-home {
  background-color: none; /* Original color for home page */
}

.navbar-chat {
  background-color: none; /* New color for chat page */
}
.navbar-container-chat{
margin: 0;


}
.navbar-auth {
  background-color: none; /* Replace with your desired color */
}


.navbar-logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.navbar-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.navbar-right {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-clear-chat {
  background-color: #f0f0f0;
  color: #0077be;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.navbar-chat .navbar-links a {
  color: #333; /* Darker color for text on chat page */
}

.logo-image {
  height: 40px; /* Adjust this value based on your logo size */
  width: auto;
  margin-right: 10px;
}

.navbar-links {
  display: flex;
  list-style: none;
}

.navbar-links li {
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-auth .navbar-links li a {
  color: white; /* Blue color for links, only on auth page */
}

.navbar-links a:hover {
  color: #f0f0f0;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
}


.navbar-clear-chat::before {
  content: "🧹";
  margin-right: 8px;
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.navbar-clear-chat::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.navbar-clear-chat:hover {
  background-color: #0077be;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 119, 190, 0.5);
}

.navbar-clear-chat:hover::before {
  transform: rotate(15deg);
}


@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.navbar-cta {
  background-color: #ffd700;
  color: #0077be;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar-cta:hover {
  background-color: #fff;
  color: #00a86b;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}



.scrolled {
  background-color: rgba(0, 119, 190, 0.5); /* Semi-transparent version of original color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


/* ... (rest of the styles remain the same) ... */

.navbar-logo {
  display: flex;
  align-items: center;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  margin-right: 0.5rem;
}

.logo-icon {
  width: 30px;
  height: 30px;
  position: relative;
}

.navbar-links {
  display: flex;
  list-style-type: none;
}

.navbar-links li {
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #ffd700;
}

.navbar-cta {
  background-color: #ffd700;
  color: #0077be;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar-cta:hover {
  background-color: #fff;
  color: #00a86b;
}

.navbar.scrolled {
  background-color: rgba(0, 119, 190, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-cta {
    display: none;
  }

  /* Add a hamburger menu for mobile here */
}
@media (max-width: 768px) {
  .navbar {
    height: auto;
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    height: auto;
    min-height: 120px; /* Increased minimum height */
    margin-bottom: 15px;
  }

  .navbar-logo {
    margin-bottom: 1rem;
  }

  .navbar-center {
    position: static;
    transform: none;
  }

  .navbar-right {
    justify-content: center;
  }

  .navbar-buttons {
    display: flex;
    flex-direction: row; /* Keep buttons on the same line */
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  .navbar-clear-chat,
  .navbar-cta {
    padding: 0.4rem 0.8rem;
    font-size: 0.9em;
    flex: 1; /* Make buttons grow equally */
    max-width: 100%; /* Limit maximum width */
  }

  .navbar-links {
    display: none;
  }

  .navbar-cta {
    display: block;
  }
}