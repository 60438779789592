/* src/reset.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

html, body {
  height: 100%;
}

#root {
  min-height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

ul, ol {
  list-style: none;
}