.creative-file-uploader {
  font-family: 'Poppins', sans-serif;
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.tab-slider {
  display: flex;
  background-color: #e0e0e0;
  border-radius: 50px;
  padding: 5px;
  position: relative;
  margin-bottom: 2rem;
}

.slider-background {
  position: absolute;
  width: 50%;
  height: 90%;
  background-color: #ffffff;
  border-radius: 50px;
  transition: transform 0.3s ease;
}

.tab-button {
  flex: 1;
  padding: 10px;
  border: none;
  background: none;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease;
}

.tab-button.active {
  color: #4a90e2;
}

.drop-zone {
  border: 3px dashed #c3cfe2;
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.drop-zone:hover, .drop-zone.drag-active {
  border-color: #4a90e2;
  background-color: rgba(74, 144, 226, 0.1);
}

.drop-zone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}
.drop-zone.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.upload-button.disabled {
  background-color: #b0b0b0;
  color: #666666;
  cursor: not-allowed;
}
.upload-icon, .file-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease;
}

.file-icon {
  animation: pop-in 0.3s ease-out;
}

@keyframes pop-in {
  0% { transform: scale(0); }
  70% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.file-name {
  font-weight: 600;
  color: #4a90e2;
}

.error-message {
  background-color: #ff6b6b;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: center;
  animation: fade-in 0.3s ease-out;
}

@keyframes fade-in {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.upload-button {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  border: none;
  background-color: #4a90e2;
  color: white;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-button:hover:not(:disabled) {
  background-color: #3a7bc8;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(74, 144, 226, 0.4);
}


.upload-button.uploading {
  background-color: #feca57;
  animation: pulse 1s infinite;
}

.upload-button.uploaded {
  background-color: #5ed5a8;
}

.upgrade-message {
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
  animation: fade-in 0.3s ease-out;
}

.upgrade-message a {
  color: #4a90e2;
  text-decoration: none;
}

.upgrade-message a:hover {
  text-decoration: underline;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}