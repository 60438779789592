@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

  --color-bg: white;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #59A3E7;
  --color-subtext: #FF8A71;
  --color-bg-purple: #AE67FA;
  --color-bg-orange: #F67600;
   /* Light mode variables */
  --color-text-light: #000000;  /* Black text for light mode */
  --color-text-light-footer: #777;
  --color-bg-purple-light: #e2c6ff;
  --color-bg-orange-light: #ffdfb9;
  --color-bg-orange-lightest: #fff4e6;
  --color-bg-purple-lightest: #f0e1ff;
  --color-bg-light:  linear-gradient(to bottom, #ffffff, #fdfdfd, #fafafb, #f8f8f8, #f6f5f6, #f6f4f6, #f5f2f5, #f5f1f5, #f6f0f7, #f7eff9, #f7effc, #f6eeff);
  --color-bg-chat-light: linear-gradient(to top, #ffffff, #fdfdfd, #fafafb, #f8f8f8, #f6f5f6, #f6f4f6, #f5f2f5, #f5f1f5, #f6f0f7, #f7eff9, #f7effc, #f6eeff);
   /* Dark mode variables */
  --color-text-dark: #FFFFFF;   /* White text for dark mode */
  --color-bg-dark: linear-gradient(to bottom, #000000, #050103, #090208, #0a040e, #090713, #090a18, #080d1c, #051021, #051328, #031530, #011737, #00193f);

}