/* HeroSection.css */
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #0077be, #00a86b);
  color: #fff;
}

.hero-content {
  text-align: center;
  max-width: 600px;
}

.hero-title {
  font-size: 4rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.highlight {
  color: #ffd700;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.hero-cta {
  display: flex;
  justify-content: center;
}

.cta-button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary {
  background-color: #ffd700;
  color: #0077be;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }
}

