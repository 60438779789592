
.userInput-root {
  width: 70%;             /* Set the width to 70% of its parent */
  position: fixed;         /* Fixed positioning relative to the viewport */
  bottom: 0;               /* Align to the bottom of the viewport */
  left: 50%;               /* Start at the middle of the viewport */
  transform: translateX(-50%); /* Offset by half its own width to center it */
  z-index: 100;           /* Ensure it's above other elements */
  /* Other styles like padding, border, etc., as needed */
}

.input-group {
    display: flex;
    background-color: white;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;
    margin-top: 20px;
    border-top: 2px solid black;
    border-left: 2px solid black;
        border-right: 2px solid black;
    border-radius: 10px 10px 0 0px;
}

/*This controls all the input boxes styles*/
.message-input {
    width: 80%;
    min-height: 30px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    resize: none;
    margin-bottom: 10px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    background: var(--color-bg-light-footer);
    color: #000000;
    border: none;
    outline: none;
}

.input-button {
    border: none;
  background-color: transparent;
  cursor: pointer;
  height: 50px; /* Match the height of the .message-input */
  display: flex; /* Added for centering the icon vertically and horizontally */
  align-items: center;
  justify-content: center;
  width: 40px; /* This is an arbitrary width, adjust as needed to give the icon space */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
/* Updated styles for the PDF button and icon */
.pdf-button-container{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pdf-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 50px; /* Match the height of the .message-input */
  display: flex; /* Added for centering the icon vertically and horizontally */
  align-items: center;
  justify-content: center;
  width: 40px; /* This is an arbitrary width, adjust as needed to give the icon space */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

}

.pdf-button img, .input-button img {
  height: auto;
  width: 80%;
  object-fit: contain;
}
