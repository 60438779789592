
.chatArea{
margin-bottom: 10vh;
height: 100%;

}
.message-response-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.topMessage{
font-family: var(--font-family);

}

.topMessage p{
color: white;

}
.user-message {
  align-self: flex-end;
  max-width: 80%;
  background-color: #dcf8c6;
  border-radius: 20px 20px 0 20px;
  padding: 10px 15px;
  margin-bottom: 10px;

  /* existing styles */
  word-wrap: break-word;
}

.ai-response {
  align-self: flex-start;
  max-width: 80%;
  background-color: #ffffff;
  border-radius: 20px 20px 20px 0;
  padding: 10px 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ai-response.loading {
  background-color: #f0f0f0;
}

.ai-header {
  margin-bottom: 5px;
  font-weight: bold;
}

.user-message p, .ai-response p {
  margin: 0;
  color: #000000;
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 1.4;
}

.topMessage {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #888;
}

.user-text {
  color: #00a86b;
  font-weight: 600;
}

.helix-text, .sherpa-text {
  color: #0077b7;
  font-weight: 600;
}

.source-button {
  background: none;
  border: none;
  color: #0077b7;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  padding: 0;
  text-align: left;
}

.source-container {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  position: relative;
  background: none;
  padding: 20px;
  width: 50%;
  height: 70vh;
  border-radius: 8px;
}

.modal-content .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: top;
  z-index: 999;
}

.modal-content-custom {
  position: relative;
  background: none;
  padding: 20px;
  width: 50%;
  min-height: 30vh;
  height: auto;
  border-radius: 8px;
}

.modal-content-custom .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

/* Keep existing styles for PDF viewer, loading indicator, etc. */

/* Responsive styles */
@media (max-width: 768px) {
  .chatArea {
    padding: 15px;
  }
.modal-content .close-modal-btn{
z-index: 10000;
top:40px;
}
  .user-message, .ai-response {
    max-width: 85%;
  }
}
.modal-content{
width: 90%;

}
@media (max-width: 480px) {
  .chatArea {
    padding: 10px;
  }

  .user-message, .ai-response {
    max-width: 90%;
  }
}